.App
{
	background-color: #282c34;
	min-height:       100vh;
	display:          flex;
	flex-direction:   column;
	align-items:      center;
	justify-content:  center;
}

.App-background
{
	position: fixed;
	top:      20vh;
	height:   60vh;
}

.card
{
	width: 60%;
	background-color: rgba(255,255,255,0.66) !important;
}
